import React from 'react';
import me from '../../assets/pictures/workingAtComputer.jpg';
import meNow from '../../assets/pictures/currentme.jpg';
import { Link } from 'react-router-dom';
import ResumeDownload from './ResumeDownload';

export interface AboutProps {}

const About: React.FC<AboutProps> = (props) => {
    return (
        // add on resize listener
        <div className="site-page-content">
            {/* <img src={me} style={styles.topImage} alt="" /> */}
            <h1 style={{ marginLeft: -16 }}>Welcome</h1>
            <h3>I'm Bill Hub</h3>
            <br />
            <div className="text-block">
                <p>
                    I'm a Full-stack developer!
                </p>
                <br />
                <p>
                    Thank you for taking the time to check out my portfolio. I
                    really hope you enjoy exploring it as much as I enjoyed
                    building it. If you have any questions or comments, feel
                    free to contact me at my{' '}
                    <Link to="https://discord.com/users/640512148786642947">Discord</Link> or shoot me an email at{' '}
                    <a href="mailto:El.Pepes-tf@proton.me">
                        El.Pepes-tf@proton.me
                    </a>
                </p>
            </div>
            <ResumeDownload />
            <div className="text-block">
                <h3>About Me</h3>
                <br />
                <p>
                    From a young age, I've been curious about how things work. This curiosity naturally led me to develop a strong passion for mathematics, and I found myself deeply enamored with problem-solving. During elementary school, I had the opportunity to code shapes using JavaScript, which served as my initial introduction to programming through mathematical applications.
                </p>
                <br />
                <div className="captioned-image">
                    <img src="https://cdn.discordapp.com/avatars/640512148786642947/bc5d72c0543cc2dd0a5b2ec1c869d285.png" style={styles.image} alt="" />
                    <p>
                        <sub>
                            <b>Figure 1:</b> A real photo of sad man developing this
                            website :)
                        </sub>
                    </p>
                </div>

                <p>
                    I started programming more seriously in high school,
                    initially learning how to scrape and interact with websites.
                    I went on to do a ton of passion projects, many of them. 
                    I worked on many projects together, including chat bots,
                    multiple game projects, simple app, and more.
                </p>
                <br />
                <br />
                <div style={{}}>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'justify',
                            alignSelf: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <h3>My Hobbies</h3>
                        <br />
                        <p>
                            Beyond software, I have numerous hobbies that I enjoy in my spare time. Among them, playing video games stands out as a favorite pastime.
                        </p>
                    </div>
                </div>
                <br />
                <br />
                <p>
                    Thanks for reading about me! I hope that you enjoy exploring
                    the rest of my portfolio website and everything it has to
                    offer. If you find the easter egg make sure to let me know
                    on Discord{' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://discord.com/users/640512148786642947"
                    >
                        Bill_Hub
                    </a>{' '}
                    Good luck and have fun!
                </p>
                <br />
                <p>
                    If you have any questions or comments I would love to hear
                    them. You can reach me through my{' '}
                    <Link to="https://discord.com/users/640512148786642947">Discord</Link> or shoot me an email
                    at{' '}
                    <a href="mailto:El.Pepes-tf@proton.me">
                        El.Pepes-tf@proton.me
                    </a>
                </p>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    contentHeader: {
        marginBottom: 16,
        fontSize: 48,
    },
    image: {
        height: 'auto',
        width: '100%',
    },
    topImage: {
        height: 'auto',
        width: '100%',
        marginBottom: 32,
    },
    verticalImage: {
        alignSelf: 'center',
        // width: '80%',
        marginLeft: 32,
        flex: 0.8,

        alignItems: 'center',
        // marginBottom: 32,
        textAlign: 'center',
        flexDirection: 'column',
    },
};

export default About;
